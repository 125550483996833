import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  standalone: true,
})
export class GalleryComponent {
  @Input({ transform: (value: IPageContentComponent): GalleryDto => value as unknown as GalleryDto }) gallery!: GalleryDto;
  enlargeImages: Image[] = [];
  displayImage?: Image;
  openedImage?: Image;
  isDisplay = false;

  constructor(private modalService: NgbModal) {}

  openImage(content: any, image: Image) {
    if (!this.gallery) return;
    this.openedImage = image;
    if (this.gallery.CssClasses && this.gallery.CssClasses.includes('DTX-GALLERY-MODAL')) {
      this.modalService.open(content, { scrollable: true, size: 'xl' });
    } else {
      this.isDisplay = true;
      this.displayImage = image;
    }
  }

  hide() {
    this.isDisplay = false;
  }

  onClose() {
    this.modalService.dismissAll();
    this.enlargeImages = [];
  }

  getSrc(ImageUrl: string) {
    const url = new URL(ImageUrl);
    url.searchParams.set('auto', 'true');
    // url.searchParams.set("format", "WebP");
    url.searchParams.set('cache', '8640000');
    url.searchParams.set('width', '2560');
    return url.toString();
  }
}
