import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Inject,
  Injectable,
  PLATFORM_ID,
  Renderer2,
  RendererFactory2 } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SEOService {
  private renderer: Renderer2;

  constructor(
    private titleSvc: Title,
    private rendererFactory: RendererFactory2,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  updateTitle(title: string) {
    this.titleSvc.setTitle(title);
  }

  updateIcon(iconHref: string) {
    if (iconHref) {
      const iconUrl = new URL(iconHref);
      iconUrl.searchParams.set('width', '256');
      iconUrl.searchParams.set('height', '256');
      iconUrl.searchParams.set('format', 'png');
      iconUrl.searchParams.set('cache', '8640000');

      const favIconId = 'dtx-fav-icon';
      let favIcon = this.document.getElementById(favIconId);
      const favIconExists = favIcon !== null;
      if (!favIcon) {
        favIcon = this.renderer.createElement('link');
        this.renderer.setAttribute(favIcon, 'id', favIconId);
        this.renderer.setAttribute(favIcon, 'rel', 'icon');
      }

      this.renderer.setAttribute(favIcon, 'href', iconUrl.toString());
      if (!favIconExists) {
        this.renderer.appendChild(this.document.head, favIcon);
      }
    }
  }

  updateMetaDescription(pageContent: Page) {
    if (isPlatformServer(this.platformId)) {
      let description = pageContent.MetaDescription;

      if (!description) {
        pageContent.PageContent?.every((content: PageContentDto) => {
          if (content.ArticleDescription) {
            description = content.ArticleDescription;
            return false;
          }
          return true;
        });
      }

      if (description) {
        const contentMeta = this.renderer.createElement('meta');
        this.renderer.setAttribute(contentMeta, 'name', 'description');
        this.renderer.setAttribute(contentMeta, 'content', description);
        this.renderer.appendChild(this.document.head, contentMeta);
      } else {
        console.warn('No description found for page', pageContent);
      }
    }
  }

  updateMetaImage(pageContent: Page) {
    if (isPlatformServer(this.platformId)) {
      let imageUrl: string | null = '';
      pageContent.PageContent?.every((content: PageContentDto) => {
        if (content.IndexImage) {
          imageUrl = content.IndexImage.ImageUrl;
          return false;
        }
        return true;
      });

      if (imageUrl) {
        const contentMeta = this.renderer.createElement('meta');
        this.renderer.setAttribute(contentMeta, 'name', 'og:image');
        this.renderer.setAttribute(contentMeta, 'content', imageUrl);
        this.renderer.appendChild(this.document.head, contentMeta);
      } else {
        console.warn('No image found for page', pageContent);
      }
    }
  }

  updateDtxDynamicStyling(pageContent: Page) {
    const stylesId = 'dtx-dynamic-styling';
    let styles = this.document.getElementById(stylesId);
    const stylesExists = styles !== null;
    if (!styles) {
      styles = this.renderer.createElement('style');
      this.renderer.setAttribute(styles, 'type', 'text/css');
      this.renderer.setAttribute(styles, 'id', stylesId);
    }

    this.renderer.setProperty(
      styles,
      'textContent',
      pageContent?.PageConfiguration?.Css,
    );
    if (!stylesExists) {
      this.renderer.appendChild(this.document.head, styles);
    }
  }

  updateDtxDynamicColours(pageContent: Page) {
    const cseId = 'dtx-dynamic-colors';
    let colourStyleElement = this.document.getElementById(cseId);
    const cseExists = colourStyleElement !== null;
    if (!colourStyleElement) {
      colourStyleElement = this.renderer.createElement('style');
      this.renderer.setAttribute(colourStyleElement, 'type', 'text/css');
      this.renderer.setAttribute(colourStyleElement, 'id', cseId);
    }

    this.renderer.setProperty(
      colourStyleElement,
      'textContent',
      this.getColourStyles(
        pageContent?.PageConfiguration?.LightThemeColours,
        pageContent?.PageConfiguration?.DarkThemeColours,
      ),
    );
    if (!cseExists) {
      this.renderer.appendChild(this.document.head, colourStyleElement);
    }

    const mtcId = 'dtx-meta-theme-color';
    let metaThemeColor = this.document.getElementById(mtcId);
    const mtcExists = metaThemeColor !== null;
    if (!metaThemeColor) {
      metaThemeColor = this.renderer.createElement('meta');
      this.renderer.setAttribute(metaThemeColor, 'id', mtcId);
    }
    this.renderer.setAttribute(metaThemeColor, 'name', 'theme-color');
    this.renderer.setAttribute(
      metaThemeColor,
      'content',
      pageContent?.PageConfiguration?.LightThemeColours?.PrimarySiteColour ??
        '#ffffff',
    );
    if (!mtcExists) {
      this.renderer.appendChild(this.document.head, metaThemeColor);
    }
  }

  updateDtxDynamicScripts(pageContent: Page) {
    const scriptsId = 'dtx-dynamic-js';
    let scripts = this.document.getElementById(scriptsId);
    const scriptsExists = scripts !== null;
    if (!scripts) {
      scripts = this.renderer.createElement('script');
      this.renderer.setAttribute(scripts, 'type', 'text/javascript');
      this.renderer.setAttribute(scripts, 'id', scriptsId);
      this.renderer.setProperty(scripts, 'defer', 'true');
    }

    this.renderer.setProperty(
      scripts,
      'textContent',
      pageContent?.PageConfiguration?.Js,
    );
    if (!scriptsExists) {
      this.renderer.appendChild(this.document.head, scripts);
    }
  }

  updateMetaCanonical(pageContent: Page) {
    if (isPlatformServer(this.platformId)) {
      if (pageContent.DefaultSite) {
        const canonicalMeta = this.renderer.createElement('link');
        this.renderer.setAttribute(canonicalMeta, 'rel', 'canonical');
        this.renderer.setAttribute(
          canonicalMeta,
          'href',
          pageContent.DefaultSite + pageContent.Slug,
        );
        this.renderer.appendChild(this.document.head, canonicalMeta);
      }
    }
  }

  private getColourStyles(
    LightThemeColours: Colours | null | undefined,
    DarkThemeColours: Colours | null | undefined,
  ) {
    return `
      :root {
        ${this.getColourVariables(LightThemeColours)}
        @media (prefers-color-scheme: dark)
        {
          ${this.getColourVariables(DarkThemeColours)}
        }
      }
    `;
  }

  private getColourVariables(ThemeColours: Colours | null | undefined): string {
    if (ThemeColours) {
      return `
      --DtxBackgroundColour: ${ThemeColours.BackgroundColour ?? 'rgba(0, 0, 0, 1)'};
      --DtxCardInteriorColour: ${ThemeColours.CardInteriorColour ?? 'rgba(255, 255, 255, 0)'};
      --DtxCardShadowColour: ${ThemeColours.CardShadowColour ?? 'rgba(0, 0, 0, 0.05)'};
      --DtxContainerInteriorColour: ${ThemeColours.ContainerInteriorColour ?? 'rgba(255, 255, 255, 0)'};
      --DtxContainerShadowColour: ${ThemeColours.ContainerShadowColour ?? 'rgba(0, 0, 0, 0.1)'};
      --DtxErrorSiteColour: ${ThemeColours.ErrorSiteColour ?? 'rgba(255, 0, 0, 1)'};
      --DtxPrimarySiteColour: ${ThemeColours.PrimarySiteColour ?? 'rgba(255, 255, 255, 0)'};
      --DtxSecondarySiteColour: ${ThemeColours.SecondarySiteColour ?? 'rgba(255, 255, 255, 0)'};
      --DtxTertiarySiteColour: ${ThemeColours.TertiarySiteColour ?? 'rgba(255, 255, 255, 0)'};
      --DtxSuccessSiteColour: ${ThemeColours.SuccessSiteColour ?? 'rgba(0, 255, 0, 1)'};
      --DtxTextColour: ${ThemeColours.TextColour ?? 'rgba(0, 0, 0, 1)'};
      --DtxLinkColour: ${ThemeColours.LinkColour ?? 'rgba(0, 0, 0, 1)'};
      --DtxMenuBackgroundColour: ${ThemeColours.MenuBackgroundColour ?? 'rgba(30, 115, 190, 0.7)'};
      --DtxMenuTextColour: ${ThemeColours.MenuTextColour ?? 'rgba(255, 255, 255, 1)'};
      --DtxHeaderBackgroundColour: ${ThemeColours.HeaderBackgroundColour ?? 'rgba(0, 0, 0, 0)'};
      --DtxHeaderTextColour: ${ThemeColours.HeaderTextColour ?? 'rgba(0, 0, 0, 1)'};
      --DtxFooterBackgroundColour: ${ThemeColours.FooterBackgroundColour ?? 'rgba(0, 0, 0, 0)'};
      --DtxFooterTextColour: ${ThemeColours.FooterTextColour ?? 'rgba(0, 0, 0, 1)'};
      `;
    }
    return '';
  }

  updateMetaAuthors(MetaAuthors: Author[]) {
    if (isPlatformServer(this.platformId) && MetaAuthors?.length > 0) {
      const authorMeta = this.renderer.createElement('meta');
      this.renderer.setAttribute(authorMeta, 'name', 'author');
      this.renderer.setAttribute(
        authorMeta,
        'content',
        MetaAuthors.map((author) => author.AuthorName).join(', '),
      );
      this.renderer.appendChild(this.document.head, authorMeta);
    }
  }

  updateMetaKeywords(MetaKeywords: string) {
    if (isPlatformServer(this.platformId) && MetaKeywords) {
      const keywordMeta = this.renderer.createElement('meta');
      this.renderer.setAttribute(keywordMeta, 'name', 'keywords');
      this.renderer.setAttribute(keywordMeta, 'content', MetaKeywords);
      this.renderer.appendChild(this.document.head, keywordMeta);
    }
  }
}
