import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { RouterLink } from '@angular/router';
import { MenuItem as PMenuItem } from 'primeng/api';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { LoginButtonComponent } from "../../../features/login/pages/login-button/login-button.component";

@Component({
  selector: 'app-header-nav-item',
  standalone: true,
  imports: [CommonModule, TieredMenuModule, RouterLink, LoginButtonComponent],
  templateUrl: './header-nav-item.component.html',
  styleUrl: './header-nav-item.component.scss',
})
export class HeaderNavItemComponent implements AfterViewInit {


  @Input()
  item!: PMenuItem;

  @Input()
  itemWidth?: number;
  @Input()
  itemGap: number = 48;
  @Input()
  menuWidth?: number = 168;
  @Input() 
  showLogonButton = false;

  @ViewChild('itemEl')
  itemEl!: ElementRef<HTMLDivElement>;

  rtl = false;
  show = false;


  ngAfterViewInit() {
    if (typeof window === 'undefined') return;
    const offsetRight = window.innerWidth - this.itemEl.nativeElement.offsetLeft - this.itemEl.nativeElement.offsetWidth;
    if (offsetRight < (this.menuWidth ?? 168)) this.rtl = true;
    this.bindMouseover();
  }

  onOutsideClickEventListener = (event: Event) => {
    if (this.itemEl?.nativeElement.contains(event.target as Node)) return;
    this.hideMenu();
  };
  showMenu = () => {
    this.show = true;
  };
  hideMenu = () => {
    this.show = false;
  };
  toggleMenu = () => {
    this.show = !this.show;
  };
  mouseentered(target: Element) {
      var htmlElement = target as HTMLElement;
      var parent = htmlElement.closest('[role="menuitem"]');
      parent?.classList.add('p-menuitem-active');
      parent?.classList.add('p-highlight');
    };

    mouseleft(target: Element) {
       var htmlElement = target as HTMLElement;
       var parent = htmlElement.closest('[role="menuitem"]');
       parent?.classList.remove('p-menuitem-active');
       parent?.classList.remove('p-highlight');
     };

     bindMouseover() {
      console.log('Entered bindMouseover');

      var menuItems = this.itemEl?.nativeElement.querySelectorAll('.p-menuitem-link.has-children');
      menuItems.forEach((item: Element) => {
        var sharedParent = item.closest('.p-menuitem');
        sharedParent?.addEventListener('mouseover', (event) => {
          this.mouseentered(item);
        });

        sharedParent?.addEventListener('mouseleave', (event) => {
          this.mouseleft(item);
        });
      });
    }
}
