<div class="{{ banners.CssClasses }} DTX_HEADER_BANNER">
  <div class="row m-0 DTX_HEADER_BANNER_ROW">
    @for (bannerImages of banners.BannerImages; track bannerImages) {
      <div class="col-12 p-0 DTX_HEADER_BANNER_COL">
        @if (hasUrl(bannerImages.Url)) {
          <a
            (click)="anchorTag($event, bannerImages.Url)"
            class="DTX_HEADER_BANNER_IMAGE_ANCHOR"
            href="{{ imageUrl(bannerImages.Url) }}"
            >
            <img
              alt="{{ bannerImages.AltText }}"
              class="{{ bannerImages.CssClasses }} DTX_HEADER_BANNER_IMAGE"
              src="{{ getSrc(bannerImages.ImageUrl) }}"
              />
          </a>
        }
        @if (!hasUrl(bannerImages.Url)) {
          <img
            alt="{{ bannerImages.AltText }}"
            class="{{ bannerImages.CssClasses }} DTX_HEADER_BANNER_IMAGE"
            src="{{ getSrc(bannerImages.ImageUrl) }}"
            />
        }
      </div>
    }
  </div>
</div>
