import { CommonModule, NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Sidebar, SidebarModule } from 'primeng/sidebar';
import { LoginButtonComponent } from '../../../features/login/pages/login-button/login-button.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterLink, NgClass, NgTemplateOutlet, LoginButtonComponent, SidebarModule],
})
export class MenuComponent implements OnInit, OnChanges {
  @Input() menuList!: any;
  @Input() showLogonButton = false;
  mainMenu!: MenuItem[];
  slug!: string;
  childCount = 0;

  sidebarVisible = false;

  // This variable is used if that particular menu is used for either header or footer
  @Input() menuRoles = false;
  menuIcon = 'fa-bars';
  classFlag = false;

  @ViewChild('sidebar')
  sidebar!: Sidebar;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.setMenuList();
  }

  setMenuList() {
    this.mainMenu = this.menuList.MenuItems;
    this.mainMenu.forEach((menu: any) => {
      if (menu.Children.length > 0) {
        this.childCount = 0;
        this.checkChild(menu);
      }
    });
  }

  onNavigationMenu() {
    this.sidebarVisible = !this.sidebarVisible;
    if (this.menuIcon === 'fa-bars') {
      this.menuIcon = 'fa-times';
      this.classFlag = true;
    } else {
      this.menuIcon = 'fa-bars';
      this.classFlag = false;
    }
    this.setMenuList();
  }

  checkChild(menu: any) {
    menu.Children.forEach((child: any, i: number) => {
      if (i === 0) {
        this.childCount += 1;
      }
      child.count = this.childCount;
      if (child.Children.length > 0) {
        this.checkChild(child);
      }
      if (menu.Children.length - 1 === i) {
        this.childCount -= 1;
      }
    });
  }

  expandSubMenu(data: any) {
    data.hide = !data.hide;
  }

  onRouting(item: any, event: Event) {
    let queryParams: any;
    let fragment = '';

    if (item.QueryParams) {
      // Extract fragment if exists
      const fragmentParts = item.QueryParams.split('#');
      if (fragmentParts.length > 1) {
        fragment = fragmentParts[1];
      }

      // Extract queryParams string
      const queryParts = fragmentParts[0].split('?');
      if (queryParts.length > 1) {
        // Extract individual query parameters
        queryParts[1].split('&').forEach((param: any) => {
          const [key, value] = param.split('=');
          if (key && value) {
            queryParams[key] = value;
          }
        });
      }
    }
    if (fragment == '') {
      this.router.navigate([item.Slug], {
        queryParams: queryParams,
      });
    } else {
      this.router.navigate([item.Slug], {
        queryParams: queryParams,
        fragment: fragment,
      });
    }
    event.preventDefault();
    this.onNavigationMenu();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['menuList']?.currentValue?.MenuItems != undefined) {
      this.mainMenu = changes['menuList'].currentValue.MenuItems;
    }
  }

  onMenuHide() {
    this.sidebar.destroyModal();
  }
}
