@for (contentItem of contentItems; track $index) {
    <div class="DTX_CONTENT_ITEM">
        @switch (contentItem.Type) {
            @case ('TextDto') {
                <app-rtext [embeddedText]="contentItem" />
            }
            @case ('Image') {
                <app-images [Image]="contentItem" [colSizes]="colSize" class="DTX_CONTENT_TYPE_IMAGE {{ contentItem.CssClasses }}" />
            }
            @case ('GalleryDto') {
                <app-gallery [gallery]="contentItem" class="DTX_CONTENT_TYPE_GALLERY {{ contentItem.CssClasses }}" />
            }
            @case ('YouTube') {
                <app-embed-video [videoLink]="contentItem" />
            }
            @case ('DocumentDto') {
                <app-documents [documents]="contentItem" />
            }
            @case ('DynamicArticlesDto') {
                <app-dynamic-articles [dynamicArticles]="contentItem" class="DTX_CONTENT_TYPE_ARTICLES" />
            }
            @case ('EmbedDto') {
                <app-embed-html [embedHtml]="contentItem" />
            }
            @case ('DynamicSubscribeDto') {
                @if (contentItem.DataExists) {
                    <app-subscribe [subscribeDetails]="contentItem" class="DTX_CONTENT_TYPE_SUBSCRIBE {{ contentItem.CssClasses }}" />
                }
            }
            @case ('Carousel') {
                <app-carousel [carouselData]="$any(contentItem)" />
            }
        }
    </div>
}
